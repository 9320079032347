import React, { useEffect, useState } from 'react'
import { API } from './api';
import Swal from 'sweetalert2';

const Admin = () => {
  const [admin, setAdmin] = useState()

  const sum3AdminToken = localStorage.getItem('sum3AdminToken')

  const getAdminDetails = async () => {
    try {
      const response = await fetch(`${API}/admin`, {
        method: 'get',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sum3AdminToken}`
        },
      });
      if (!response.ok) {
        throw new Error('Failed to Admin data');
      }
      const data = await response.json();
      setAdmin(data?.payload)
    } catch (error) {
      Swal.fire({
        text: error,
        icon: "error"
      });
    }
  };

  useEffect(() => {
    getAdminDetails()
  }, [])
  return (
    <div class='d-flex align-items-center justify-content-center' style={{ height: '100vh', width: '100%', background: 'linear-gradient(#FF823C, #424242)' }}>

      <div class='col-lg-5 col-md-6 col-11' style={{ backdropFilter: 'blur(3px)', backgroundColor: 'rgba(255, 255, 255, 0.3)', borderRadius: ' 32px', boxShadow: ' 30px 20px 60px 0px rgba(145, 192, 255, 0.5), inset -6px -6px 10px 0px rgba(145, 192, 255, 0.6), inset 0px 5px 10px 0px rgb(255, 255, 255,0.5)' }}>
        {/* <img src={require('../image/logo.png')} class='m-2' style={{height:'50px'}} alt="" /> */}
        <br />
        <p class='text-white fs-1 fw-bold'>Admin</p>
        <div class='col-11 m-auto  p-3 '>
          <div class="form-floating p-0">
            <input type="text" class="form-control" id="name" readOnly placeholder="Password" value={admin?.name} />
            <label for="name">Name</label>
          </div>
          <div class="form-floating p-0 mt-3">
            <input type="email" class="form-control" id="email" readOnly placeholder="Email" value={admin?.email} />
            <label for="email">Email</label>
          </div>
          <div class="form-floating p-0 mt-3">
            <input type="email" class="form-control" id="email" readOnly placeholder="Email" value={admin?.mobile} />
            <label for="email">Phone</label>
          </div>
          <div class="form-floating p-0 mt-3">
            <input type="email" class="form-control" id="email" readOnly placeholder="Email" value={admin?.address} />
            <label for="email">Country</label>
          </div>
          <div class="form-floating p-0 mt-3">
            <input type="email" class="form-control" id="email" readOnly placeholder="Email" value={admin?.city} />
            <label for="email">City</label>
          </div>

        </div>
        <br />
      </div>


    </div>
  )
}

export default Admin