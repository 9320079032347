import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { API } from './api';
import Swal from 'sweetalert2';


const Intermediate = () => {
    const [details, setDetails] = useState() 
    const sum3AdminToken = localStorage.getItem('sum3AdminToken')
    const navigate = useNavigate()
    const getDetails = async () => {
        try {
            const response = await fetch(`${API}/content/product-service?page=Intermediate`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
            });
            if (!response.ok) {
                navigate('/dashboard')
                throw new Error('Failed to User data');
            }
            const data = await response.json();
            setDetails(data?.payload)
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    useEffect(() => {
        getDetails()
    }, [])


    const handleChangeContant = (e) => {
        const { name, value } = e.target;
        setDetails((prev) => ({ ...prev, [name]: value }));
    };



    const updateDetails = async (el) => {
        try {
            const response = await fetch(`${API}/content/product-service/${el?.id}`, {
                method: 'PATCH',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
                body: JSON.stringify(el)
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }

            Swal.fire({
                text: data?.message,
                icon: "success"
            });
            await getDetails()
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    }; 
    
    return (
        <div>
            <div class='col-11 m-auto'>
                <p class='fs-1 fw-semibold'>Intermediate</p>

                <div class='mt-4'>

                    <div class="form-floating p-0 mt-3">
                        <input type="text" class="form-control" id="text1" placeholder="Text 1" name='text1' value={details?.text1} onChange={handleChangeContant} />
                        <label for="text1">Text 1</label>
                    </div>

                    <div class="form-floating p-0 mt-3">
                        <input type="text" class="form-control" id="text2" placeholder="Text 2" name='text2' value={details?.text2} onChange={handleChangeContant} />
                        <label for="text2">Text 2</label>
                    </div>

                    <div class="form-floating p-0 mt-3">
                        <input type="text" class="form-control" id="text3" placeholder="Text 3" name='text3' value={details?.text3} onChange={handleChangeContant} />
                        <label for="text3">Text 3</label>
                    </div>

                    <div class="form-floating p-0 mt-3">
                        <input type="text" class="form-control" id="text4" placeholder="Text 4" name='text4' value={details?.text4} onChange={handleChangeContant} />
                        <label for="text4">Text 4</label>
                    </div>

                    <div className="form-floating p-0 mt-3">
                        <textarea
                            className="form-control h-auto"
                            placeholder="Subtitle"
                            name='description'
                            value={details?.description}
                            onChange={handleChangeContant}
                        />
                        <label>Description</label>
                    </div>

                    <div class="form-floating p-0 mt-3">
                        <input type="text" class="form-control" id="btntext" placeholder="btn text" name='button_txt' value={details?.button_txt} onChange={handleChangeContant} />
                        <label for="btntext">Button Name</label>
                    </div>

                    <div class="form-floating p-0 mt-3">
                        <input type="text" class="form-control" id="url" placeholder="url" name='button_url' value={details?.button_url} onChange={handleChangeContant} />
                        <label for="url">Button Url</label>
                    </div>

                    <div class='text-start my-3'>

                    <button class='btn btn-dark' onClick={()=>updateDetails(details)}>Update</button>
                    </div>

       

                </div>
            </div>
        </div>
    )
}

export default Intermediate