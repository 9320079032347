import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { API } from './api';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { TiUserAdd } from 'react-icons/ti';
import { Navigate, useNavigate } from 'react-router-dom';

const Employees = () => {
    const [search, setSearch] = useState("")
    const [employees, setEmployees] = useState()
    const [addEmployees, setAddEmployees] = useState({
        role: "Employee"
    })
    const [isAdd, setIsAdd] = useState(false) 
    const [cat, setCat] = useState('') 
    const sum3AdminToken = localStorage.getItem('sum3AdminToken')
    const navigate = useNavigate() 
    const getEmployeesDetails = async () => {
        try {
            const response = await fetch(`${API}/admin/employees`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
            });
            if (!response.ok) {
                navigate('/dashboard')
                throw new Error('Failed to User data');
            }
            const data = await response.json();
            setEmployees(data?.payload?.employees)
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    useEffect(() => {
        getEmployeesDetails()
    }, [])

    const handleEmployeDetailChange = (index, fieldName, value) => {
        const updatedEmployeeDetails = employees.map((details, i) => {
            if (i === index) {
                return { ...details, [fieldName]: value };
            }
            return details;
        });
        setEmployees(updatedEmployeeDetails);
    };


    const postEmployeesDetails = async (el) => {
        try {
            const response = await fetch(`${API}/employees/${el?.id}`, {
                method: 'PATCH',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
                body: JSON.stringify(el)
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }

            Swal.fire({
                text: data?.message,
                icon: "success"
            });
            await getEmployeesDetails()
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    const updateEmploee = (el) => {
        postEmployeesDetails(el)
    }

    const deletEmployeesDetails = async (el) => {
        try {
            const response = await fetch(`${API}/employees/${el}`, {
                method: 'DELETE',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
            });
            if (!response.ok) {
                throw new Error('Failed to Update User data');
            }
            setEmployees(prevEmployees => prevEmployees.filter(employee => employee.id !== el));
            const data = await response.json();

            Swal.fire({
                text: data?.message || 'Employee deleted successfully',
                icon: "success"
            });
            await getEmployeesDetails()
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    const deletEmploee = (el) => {
        deletEmployeesDetails(el)
    }

    const handeleAddEmployeesDetails = (el) => {
        const { name, value } = el?.target
        setAddEmployees({ ...addEmployees, [name]: value })
    }


    const addNewEmployees = async () => {
        if (addEmployees?.name != undefined && addEmployees?.name != "" &&
            addEmployees?.email != undefined && addEmployees?.email != "" &&
            addEmployees?.role != undefined && addEmployees?.role != "" &&
            addEmployees?.mobile != undefined && addEmployees?.mobile != ""

        ) {
            try {
                const response = await fetch(`${API}/employees`, {
                    method: 'POST',
                    headers: {
                        Accept: '*/*',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${sum3AdminToken}`
                    },
                    body: JSON.stringify(addEmployees)
                });
                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data?.message);
                }
                const data = await response.json();

                Swal.fire({
                    text: data?.message,
                    icon: "success"
                });
                setIsAdd(false)
                setAddEmployees('')
                await getEmployeesDetails()
            } catch (error) {
                Swal.fire({
                    text: error,
                    icon: "error"
                });
            }
        } else {
            Swal.fire({
                text: "Please add information",
                icon: "error"
            });
        }
    };


    return (
        <div>
            {isAdd &&

                <div class='popupStyle'>
                    <div class='d-flex align-items-center justify-content-center' style={{ width: '100%', height: '95vh', overflowY: 'auto' }}>

                        <div class='col-sm-10 col-11 bg-light rounded-4 m-auto'>
                            <br />
                            <p class='fs-2 fw-semibold my-3'>Add Employees</p>
                            <div class='row'>
                                <div class='col-md-6'>
                                    <div class="col-11 m-auto form-floating p-0 mt-3">
                                        <input type="text" class="form-control" id="name" placeholder="Name" name='name' value={addEmployees?.name} onChange={handeleAddEmployeesDetails} />
                                        <label for="name">Name</label>
                                    </div>
                                </div>
                                <div class='col-md-6'>
                                    <div class="col-11 m-auto form-floating p-0 mt-3">
                                        <input type="email" class="form-control" id="email" placeholder="Email" name='email' value={addEmployees?.email} onChange={handeleAddEmployeesDetails} />
                                        <label for="email">Email</label>
                                    </div>
                                </div>
                                {/* <div class='col-md-6'>
                            <div class="col-11 m-auto form-floating p-0 mt-3">
                                <input type="text" class="form-control" id="employee" placeholder="Employee" name='name' value={addEmployees?.name} onChange={handeleAddEmployeesDetails} />
                                <label for="employee">Employee</label>
                            </div>
                        </div> */}
                                <div class='col-md-6'>
                                    <select class=" form-select-lg col-11 m-auto mt-3 " aria-label="Large select example" name='role' value={addEmployees?.role} onChange={handeleAddEmployeesDetails}>
                                        <option selected value="Employee">Employee</option>
                                        <option value="Admin">Admin</option>
                                    </select>
                                </div>
                                <div class='col-md-6'>
                                    <div class="col-11 m-auto form-floating p-0 mt-3">
                                        <input type="text" class="form-control" id="mobile" placeholder="Mobile" name='mobile' value={addEmployees?.mobile} onChange={handeleAddEmployeesDetails} />
                                        <label for="mobile">Mobile</label>
                                    </div>
                                </div>
                                <div class='col-md-6'>
                                    <div class="col-11 m-auto form-floating p-0 mt-3">
                                        <input type="text" class="form-control" id="city" placeholder="City" name='city' value={addEmployees?.city} onChange={handeleAddEmployeesDetails} />
                                        <label for="city">City</label>
                                    </div>
                                </div>
                                <div class='col-md-6'>
                                    <div class="col-11 m-auto form-floating p-0 mt-3">
                                        <input type="text" class="form-control" id="address" placeholder="Address" name='address' value={addEmployees?.address} onChange={handeleAddEmployeesDetails} />
                                        <label for="address">Address</label>
                                    </div>
                                </div>


                                <div class='col-md-6'>
                                    <div class="col-11 m-auto form-floating p-0 mt-3">
                                        <input type="text" class="form-control" id="password" placeholder="Password" name='password' value={addEmployees?.password} onChange={handeleAddEmployeesDetails} />
                                        <label for="password">Password</label>
                                    </div>
                                </div>


                                <div class='col-md-12 d-flex align-items-center justify-content-center my-3 gap-3'>
                                    <button class='btn btn-outline-success rounded-5 fw-semibold' onClick={() => addNewEmployees()}>Add Employees</button>
                                    <button class='btn btn-outline-danger rounded-5 fw-semibold' onClick={() => setIsAdd(false)}>Close</button>
                                </div>

                            </div>


                            <br />
                        </div>
                    </div>
                </div>
            }
            <div class='col-11 m-auto text-start'>
                <p class='fs-1 fw-bold my-3 text-center'>Employees</p>
                <div class='d-flex gap-3'>
                    <button class='btn btn-dark fw-bold' onClick={() => setIsAdd(true)}> <TiUserAdd class='fs-4' /> Create Employees</button>
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('')}> All</button>
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('Employee')}> Employee</button>
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('Admin')}> Admin</button>
                   
                </div>
                <input type="text" placeholder='Search Products' class='form-control mt-2' value={search} onChange={(e) => setSearch(e?.target?.value)} />

                <div class=' mt-2' style={{ overflowX: 'auto' }}>
                    <table class="table table-bordered p-0    ">
                        <thead>
                            <tr >
                                <th scope="col">No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Role</th>
                                <th scope="col">City</th>
                                <th scope="col">Address</th>

                                <th scope="col">Mobie</th>
                                <th scope="col">Changes</th>


                            </tr>
                        </thead>
                        <tbody class='p-0'>
                            {employees?.filter((el) => {
                            if (cat === "") {
                                return el;
                            } else if (el.role && el.role.toLowerCase().includes(cat.toLowerCase())) {
                                return el;
                            } else {
                                return false; // Ensures that elements with null or undefined categories are excluded
                            }
                        })?.filter((el) => {
                                if (search == "") {
                                    return el;
                                } else if (el.name.toLowerCase().includes(search.toLowerCase())) {
                                    return el;
                                } else if (el.email.toLowerCase().includes(search.toLowerCase())) {
                                    return el;
                                } else if (el.role.toLowerCase().includes(search.toLowerCase())) {
                                    return el;
                                }
                            })?.map((el, index) => {
                                return (

                                    <tr class='p-0 m-0' >
                                        <th class='p-0' scope="row"><input type="text" class='form-control border-0 p-1 px-2' readOnly style={{ maxWidth: '30px' }} value={index + 1} /></th>
                                        <td class='p-0'><input type="text" class='form-control border-0 p-1' style={{ minWidth: '150px' }} name='name' value={el?.name} onChange={(e) => handleEmployeDetailChange(index, 'name', e.target.value)} /></td>
                                        <td class='p-0'><input type="text" class='form-control border-0 p-1' style={{ minWidth: '250px' }} readOnly value={el?.email} /></td>
                                        <td class='p-0'><select class="form-select" style={{ minWidth: '100px' }} aria-label=" select example" name="role" value={el?.role} onChange={(e) => handleEmployeDetailChange(index, 'role', e.target.value)}>
                                            <option selected value="Admin">Admin</option>
                                            <option value="Employee">Employee</option>
                                        </select>
                                        </td>
                                        <td class='p-0'><input type="text" class='form-control border-0 p-1' style={{ minWidth: '100px' }} name='city' value={el?.city} onChange={(e) => handleEmployeDetailChange(index, 'city', e.target.value)} /></td>
                                        <td class='p-0'><input type="text" class='form-control border-0 p-1' style={{ minWidth: '100px' }} name='address' value={el?.address} onChange={(e) => handleEmployeDetailChange(index, 'address', e.target.value)} /></td>

                                        <td class='p-0'><input type="text" class='form-control border-0 p-1' style={{ minWidth: '100px' }} name='mobile' value={el?.mobile} onChange={(e) => handleEmployeDetailChange(index, 'mobile', e.target.value)} /></td>
                                        <td class='p-0'>
                                            <div class='d-flex gap-2'>
                                                <button class='btn  btn-outline-success p-0 px-2 m-1 ' onClick={() => updateEmploee(el)}>Save</button> <button class='btn m-1  btn-outline-danger p-0 px-2' onClick={() => deletEmploee(el?.id)}><RiDeleteBin6Fill /></button>
                                            </div>
                                        </td>


                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Employees