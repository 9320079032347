import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  let sum3AdminToken = localStorage.getItem("sum3AdminToken");
  let sum3AdminRole = localStorage.getItem("sum3AdminRole");

  if (!sum3AdminToken && !sum3AdminRole) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};
