import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { API } from './api';
import { RiDeleteBin6Fill, RiQuestionnaireFill } from 'react-icons/ri';
import { TiUserAdd } from 'react-icons/ti';

const QuestionAnswer = () => {
    const [qusAns, setQusAns] = useState()
    const [addQusAns, setAddQusAns] = useState({
        category: "1"
    })
    const [category, setCategory] = useState(1)
    const [isAdd, setIsAdd] = useState(false)
    const [search, setSearch] = useState('')
    const [cat, setCat] = useState('')

    const sum3AdminToken = localStorage.getItem('sum3AdminToken')
    const sum3AdminRole = localStorage.getItem('sum3AdminRole')


    const getQuestionDetails = async () => {
        try {
            const response = await fetch(`${API}/questions/all`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }
            const convertedQuestion = data?.payload?.convertedQuestion.map(item => ({
                ...item,
                category: String(item.category) // Converts the 'category' property to a string
            }));
            setQusAns(convertedQuestion)
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    useEffect(() => {
        getQuestionDetails()
    }, [])

    const handeleAddQuestionAns = (el) => {
        const { name, value } = el?.target
        setAddQusAns({ ...addQusAns, [name]: value })
    }

    const postAddQusAns = async () => {
        try {
            const response = await fetch(`${API}/questions/create`, {
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
                body: JSON.stringify(addQusAns)
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }
            setAddQusAns('')
            setIsAdd(false)
            Swal.fire({
                text: data?.message,
                icon: "success"
            });
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    const addNewQusAns = () => {
        if (addQusAns?.title != "" && addQusAns?.title != undefined &&
            addQusAns?.category != "" && addQusAns?.category != undefined &&
            addQusAns?.correct_option != "" && addQusAns?.correct_option != undefined &&
            addQusAns?.trivia != "" && addQusAns?.trivia != undefined) {
            if (
                addQusAns?.option1 != "" && addQusAns?.option1 != undefined &&
                addQusAns?.option2 != "" && addQusAns?.option2 != undefined
            ) {
                postAddQusAns()
            }
            else {
                Swal.fire({
                    title: "Required Options",
                    text: "Please ensure that Options 1 & 2 are filled. Options 3 & 4 are optional.",
                    icon: "question"
                });

            }
        } else {
            Swal.fire({
                title: "All fields are required",
                // text: "Please ensure that at least Option 1 and Option 2 are Field.",
                icon: "error"
            });
        }
    }

    const handleUpdateQuestion = (index, fieldName, value) => {
        const updatedQnsAndAns = qusAns.map((details, i) => {
            if (i === index) {
                return { ...details, [fieldName]: value };
            }
            return details;
        });
        setQusAns(updatedQnsAndAns);
    } 

    const updateQusAns = (el) => {  
        updateSubmitQusAns(el)
    }

    const updateSubmitQusAns = async (el) => {
        try {
            const response = await fetch(`${API}/questions/${el?.id}`, {
                method: 'PATCH',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
                body: JSON.stringify(el)
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }
            Swal.fire({
                text: data?.message,
                icon: "success"
            });
            await getQuestionDetails()
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    const qusAnsDelet = async (el) => {
        try {
            const response = await fetch(`${API}/questions/${el?.id}`, {
                method: 'DELETE',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
            });
            if (!response.ok) {
                throw new Error('Failed to Update User data');
            }
            const data = await response.json();
            Swal.fire({
                text: data?.message,
                icon: "success"
            });
            await getQuestionDetails()
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    
    console.log(qusAns);
    return (
        <div>
            {isAdd &&

                <div class='popupStyle'>
                    <div class='col-12 m-auto' style={{ height: '95vh', overflowY: 'auto' }}>

                        <div class='col-sm-10 col-11 bg-light rounded-4 m-auto'>
                            <br />
                            <p class='fs-2 fw-semibold my-3'>Add Questions & Answer</p>
                            <div class='text-start col-11 m-auto'>
                                <div class="form-floating p-0">
                                    <textarea class="form-control h-auto" id="qus" placeholder="Questions" name="title" value={addQusAns?.title} onChange={handeleAddQuestionAns} />
                                    <label for="qus">Questions</label>
                                </div>
                            </div>
                            <div class='col-11 m-auto mt-3 ' style={{ height: '2px', background: "#FF823C" }} ></div>

                            <div class='col-11 m-auto row justify-content-between'>
                                <div class="form-floating p-0 col-md-5 mt-3">
                                    <textarea class="form-control h-auto" id="option1" placeholder="Option 1" name="option1" value={addQusAns?.option1} onChange={handeleAddQuestionAns} />
                                    <label for="option1">Option 1</label>
                                </div>

                                <div class="form-floating p-0 col-md-5 mt-3">
                                    <textarea class="form-control h-auto" id="option2" placeholder="Option 2" name="option2" value={addQusAns?.option2} onChange={handeleAddQuestionAns} />
                                    <label for="option2">Option 2</label>
                                </div>

                                <div class="form-floating p-0 col-md-5 mt-3">
                                    <textarea class="form-control h-auto" id="option3" placeholder="Option 3" name="option3" value={addQusAns?.option3} onChange={handeleAddQuestionAns} />
                                    <label for="option3">Option 3</label>
                                </div>

                                <div class="form-floating p-0 col-md-5 mt-3">
                                    <textarea class="form-control h-auto" id="option4" placeholder="Option 4" name="option4" value={addQusAns?.option4} onChange={handeleAddQuestionAns} />
                                    <label for="option4">Option 4</label>
                                </div>
                            </div>

                            <div class='col-11 m-auto mt-3 ' style={{ height: '2px', background: "#FF823C" }}  ></div>

                            <div class='col-11 m-auto row justify-content-between'>
                                {/* <div class="form-floating p-0 col-md-5 mt-3">
                                    <textarea class="form-control h-auto" id="ans" placeholder="Currect Option" name="correct_option" value={addQusAns?.correct_option} onChange={handeleAddQuestionAns} />
                                    <label for="ans">Currect Option</label>
                                </div> */}

                                <div class="p-0 col-md-5 mt-3 text-start">
                                    <p class="fw-semibold">Currect Option</p>
                                    <select class="form-select form-select-lg mt-1" aria-label="Large select example" name="correct_option" value={addQusAns?.correct_option} onChange={handeleAddQuestionAns}>
                                        <option selected value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                        <option value="option4">Option 4</option>

                                    </select>

                                </div>

                                <div class="p-0 col-md-5 mt-3 text-start">
                                    <p class="fw-semibold">Category</p>
                                    <select class="form-select form-select-lg mt-1" aria-label="Large select example" name="category" value={addQusAns?.category} onChange={handeleAddQuestionAns}>
                                        <option selected value='1'>Basic</option>
                                        <option value='2'>Intermediate</option>
                                        <option value='3'>Pro</option>
                                    </select>

                                </div>

                            </div>

                            <div class='col-11 m-auto mt-3 ' style={{ height: '2px', background: "#FF823C" }} ></div>

                            <div class='col-11 m-auto row justify-content-between'>
                                <div class="form-floating p-0  mt-3">
                                    <textarea class="form-control h-auto" id="trivia" placeholder="Trivia" name="trivia" value={addQusAns?.trivia} onChange={handeleAddQuestionAns} />
                                    <label for="trivia">Trivia</label>
                                </div>
                            </div>

                            <div class='col-11 m-auto mt-3 d-flex gap-3'>
                                <button class='btn btn-success' onClick={() => addNewQusAns()}>Add Question</button>
                                <button class='btn btn-outline-danger' onClick={() => setIsAdd(false)}>Close</button>

                            </div>
                            <br /><br />

                        </div>
                    </div>
                </div>
            }
            <div class='col-11 m-auto text-start'>
                <p class='fs-1 fw-bold my-3 text-center'>Question/Answer</p>
                <div class='d-flex gap-3 mt-2 flex-wrap'>
                {
                    sum3AdminRole == 'Admin' &&
                    <button class='btn btn-dark fw-bold' onClick={() => setIsAdd(true)}> <RiQuestionnaireFill class='fs-4' /> Create Qus/Ans</button>
                }
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('')}> All</button>

                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('1')}> Basic</button>
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('2')}> Intermediate</button>
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('3')}>  Pro</button>
                </div>

             
                <input type="text" placeholder='Search Queston' class='form-control mt-2' value={search} onChange={(e) => setSearch(e?.target?.value)} />
                <div>
                    {
                        qusAns?.filter((el) => {
                            if (cat == "") {
                                return el;
                            } else if (el.category.toLowerCase().includes(cat.toLowerCase())) {
                                return el;
                            }
                        })?.filter((el) => {
                            if (search == "") {
                                return el;
                            } else if (el.title.toString().includes(search.toString())) {
                                return el;
                            }
                        })?.map((el, i) => { 
                            return (
                                <div>
                                    <div class='rounded-3 border border-2 border-black mt-3 p-2'>
                                        <div class='d-flex gap-2'><b>{i + 1}.</b>
                                            <textarea id="" class='col-11 border-0' name='title' value={el?.title} onChange={(e) => handleUpdateQuestion(i, 'title', e.target.value)} ></textarea>
                                        </div>
                                        <p class="fw-semibold">Category</p>
                                    <select class="form-select form-select-lg mt-1" aria-label="Large select example" name="category" value={el?.category.toString()} onChange={(e) => handleUpdateQuestion(i, 'category', e.target.value)} >
                                        <option  value='1'>Basic</option>
                                        <option value='2'>Intermediate</option>
                                        <option value='3'>Pro</option>
                                    </select>
                                        <div>
                                            <p class='fw-bold mt-2'>Options</p>

                                            <div class=' m-auto row justify-content-between'>
                                                <div class="form-floating p-0 col-md-5 mt-2">
                                                    <textarea class="form-control h-auto" id="option1" placeholder="Option 1" name="option1" value={el?.option1} onChange={(e) => handleUpdateQuestion(i, 'option1', e.target.value)} />
                                                    <label for="option1">Option 1</label>
                                                </div>

                                                <div class="form-floating p-0 col-md-5 mt-2">
                                                    <textarea class="form-control h-auto" id="option2" placeholder="Option 2" name="option2" value={el?.option2} onChange={(e) => handleUpdateQuestion(i, 'option2', e.target.value)} />
                                                    <label for="option2">Option 2</label>
                                                </div>

                                                <div class="form-floating p-0 col-md-5 mt-2">
                                                    <textarea class="form-control h-auto" id="option3" placeholder="Option 3" name="option3" value={el?.option3} onChange={(e) => handleUpdateQuestion(i, 'option3', e.target.value)} />
                                                    <label for="option3">Option 3</label>
                                                </div>

                                                <div class="form-floating p-0 col-md-5 mt-2">
                                                    <textarea class="form-control h-auto" id="option4" placeholder="Option 4" name="option4" value={el?.option4} onChange={(e) => handleUpdateQuestion(i, 'option4', e.target.value)} />
                                                    <label for="option4">Option 4</label>
                                                </div>


                                            </div>
                                            <p class='fw-bold mt-2'>Currect Option</p>

                                            <select class="form-select form-select-lg mt-1" aria-label="Large select example" name="correct_option" value={el?.correct_option} onChange={(e) => handleUpdateQuestion(i, 'correct_option', e.target.value)} >
                                                <option value={"option1"}>{el?.option1}</option>
                                                <option value={"option2"}>{el?.option2}</option>
                                                <option value={"option3"}>{el?.option3}</option>
                                                <option value={"option4"}>{el?.option4}</option>
                                            </select>

                                            <p class='fw-bold mt-2'>Trivia</p>
                                            <div class="form-floating p-0  mt-1">
                                                <textarea class="form-control h-auto" id="trivia" placeholder="Trivia" name="trivia" value={el?.trivia} onChange={(e) => handleUpdateQuestion(i, 'trivia', e.target.value)} />
                                                <label for="trivia">Trivia</label>
                                            </div>

                                        </div>
                                        {
                                            sum3AdminRole == "Admin" &&
                                            <div class='d-flex gap-3 align-items-center mt-2'>
                                                <button class='btn btn-success' onClick={() => updateQusAns(el)}>Update</button>
                                                <button class='btn btn-danger' onClick={() => qusAnsDelet(el)}>Delete</button>
                                            </div>
                                        }

                                    </div>
                                </div>

                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default QuestionAnswer