import React from 'react'
import { BsQuestionSquareFill } from 'react-icons/bs'
import { FaRegEdit, FaUserCog, FaUserEdit } from 'react-icons/fa'
import { RiAdminFill, RiPagesLine, RiProductHuntFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

const opstion = [
  {
    title: "Admin Profile",
    icon: <RiAdminFill />,
    url: 'admin'
  },
  {
    title: "Users Profile",
    icon: <FaUserCog />,
    url: 'user'
  },
  {
    title: "Employees",
    icon: <FaUserEdit />,
    url: 'employees'
  },
  {
    title: "Questions",
    icon: <BsQuestionSquareFill />,
    url: 'questions-answer'
  },
  {
    title: "Products",
    icon: <RiProductHuntFill />,
    url: 'products'
  },
  // {
  //   title: "Home Page",
  //   icon: <RiPagesLine />,
  //   url: 'home'
  // },
  // {
  //   title: "About Page",
  //   icon: <RiPagesLine />,
  //   url: 'about'
  // },
  // {
  //   title: "Service Page",
  //   icon: <RiPagesLine />,
  //   url: 'service'
  // },
  // {
  //   title: "Sum Bot Page ",
  //   icon: <RiPagesLine />,
  //   url: 'sum-bot'
  // },
  // {
  //   title: "Basic User Page",
  //   icon: <RiPagesLine />,
  //   url: 'basic-user'
  // },
  // {
  //   title: "Pro User Page",
  //   icon: <RiPagesLine />,
  //   url: 'pro'
  // },
  // {
  //   title: "Intermediate User Page",
  //   icon: <RiPagesLine />,
  //   url: 'intermediate'
  // },

]
const Dashboard = () => {
  const navigate = useNavigate()

  const sum3AdminRole = localStorage.getItem('sum3AdminRole')

  return (
    <div>
      <div class='d-flex align-items-center justify-content-center h-100 my-5'>
        <div class='col-lg-10 col-11 row'>
          <div class='col-lg-3 col-md-4 col-sm-6 mt-4 m-auto' onClick={() => navigate(`/profile`)}>
            <div class='col-11 rounded-4 cartBox' >
              <br />
              <p class=' display-4 '><RiAdminFill /></p>
              <p class='col-11 m-auto fs-5 fw-bold my-3'>Profile</p>
              <br />
            </div>
          </div>

          <div class='col-lg-3 col-md-4 col-sm-6 mt-4 m-auto' onClick={() => navigate(`/user`)}>
            <div class='col-11 rounded-4 cartBox' >
              <br />
              <p class=' display-4 '><FaUserCog /></p>
              <p class='col-11 m-auto fs-5 fw-bold my-3'>Users Profile</p>
              <br />
            </div>
          </div>

          {sum3AdminRole == 'Admin' &&
            <div class='col-lg-3 col-md-4 col-sm-6 mt-4 m-auto' onClick={() => navigate(`/employees`)}>
              <div class='col-11 rounded-4 cartBox' >
                <br />
                <p class=' display-4 '><FaUserEdit /></p>
                <p class='col-11 m-auto fs-5 fw-bold my-3'>Employees</p>
                <br />
              </div>
            </div>
          }

          <div class='col-lg-3 col-md-4 col-sm-6 mt-4 m-auto' onClick={() => navigate(`/questions-answer`)}>
            <div class='col-11 rounded-4 cartBox' >
              <br />
              <p class=' display-4 '><BsQuestionSquareFill /></p>
              <p class='col-11 m-auto fs-5 fw-bold my-3'>Questions</p>
              <br />
            </div>
          </div>

          <div class='col-lg-3 col-md-4 col-sm-6 mt-4 m-auto' onClick={() => navigate(`/products`)}>
            <div class='col-11 rounded-4 cartBox' >
              <br />
              <p class=' display-4 '><RiProductHuntFill /></p>
              <p class='col-11 m-auto fs-5 fw-bold my-3'>Products</p>
              <br />
            </div>
          </div>

          {sum3AdminRole == 'Admin' &&
            <div class='col-lg-3 col-md-4 col-sm-6 mt-4 m-auto' onClick={() => navigate(`/basic`)}>
              <div class='col-11 rounded-4 cartBox' >
                <br />
                <p class=' display-4 '><FaRegEdit /></p>
                <p class='col-11 m-auto fs-5 fw-bold my-3'>Basic</p>
                <br />
              </div>
            </div>
          }


          {sum3AdminRole == 'Admin' &&
            <div class='col-lg-3 col-md-4 col-sm-6 mt-4 m-auto' onClick={() => navigate(`/intermediate`)}>
              <div class='col-11 rounded-4 cartBox' >
                <br />
                <p class=' display-4 '><FaRegEdit /></p>
                <p class='col-11 m-auto fs-5 fw-bold my-3'>Intermediate</p>
                <br />
              </div>
            </div>
          }
          {sum3AdminRole == 'Admin' &&
            <div class='col-lg-3 col-md-4 col-sm-6 mt-4 m-auto' onClick={() => navigate(`/pro`)}>
              <div class='col-11 rounded-4 cartBox' >
                <br />
                <p class=' display-4 '><FaRegEdit /></p>
                <p class='col-11 m-auto fs-5 fw-bold my-3'>Pro</p>
                <br />
              </div>
            </div>
          }
          {sum3AdminRole == 'Admin' &&
            <div class='col-lg-3 col-md-4 col-sm-6 mt-4 m-auto' onClick={() => navigate(`/service`)}>
              <div class='col-11 rounded-4 cartBox' >
                <br />
                <p class=' display-4 '><FaRegEdit /></p>
                <p class='col-11 m-auto fs-5 fw-bold my-3'>Service</p>
                <br />
              </div>
            </div>
          }


          {/* {sum3AdminRole == 'Admin' &&
            <div class='col-lg-3 col-md-4 col-sm-6 mt-4 m-auto' onClick={() => navigate(`/home`)}>
              <div class='col-11 rounded-4 cartBox' >
                <br />
                <p class=' display-4 '><FaRegEdit /></p>
                <p class='col-11 m-auto fs-5 fw-bold my-3'>Home</p>
                <br />
              </div>
            </div>
          } */}


        </div>
      </div>
    </div>
  )
}

export default Dashboard