import React, { useEffect, useState } from 'react'
import { API } from './api';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const Home = () => {

    const [details, setDetails] = useState()
    const sum3AdminToken = localStorage.getItem('sum3AdminToken')
    const navigate = useNavigate()

    const [homeTop, setHomeTop] = useState()
    const [checkSelf, setCheckSelf] = useState()
    const [counterCart, setCounterCart] = useState()
    const [homeSecond, setHomeSecond] = useState()
    const [participate, setParticipate] = useState()
    const [partner, setPartner] = useState()
    const [portfolio, setPortfolio] = useState()
    const [saving, setSaving] = useState()
    const [services, setServices] = useState()
    const [what, setWhat] = useState()
    const [why, setWhy] = useState()




    useEffect(() => {
        setHomeTop(details?.homeTop)
        setCheckSelf(details?.checkSelf)
        setCounterCart(details?.counterCart)
        setHomeSecond(details?.homeSecond)
        setParticipate(details?.participate)
        setPartner(details?.partner)
        setPortfolio(details?.portfolio)
        setSaving(details?.saving)
        setServices(details?.services)
        setWhat(details?.what)
        setWhy(details?.why)
    }, [details])



    const getDetails = async () => {
        try {
            const response = await fetch(`${API}/content/home/all`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
            });
            if (!response.ok) {
                navigate('/dashboard')
                throw new Error('Failed to User data');
            }
            const data = await response.json();
            setDetails(data?.payload)
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    useEffect(() => {
        getDetails()
    }, [])



    // console.log(details);
    // console.log(homeTop);


    const heandelChangTopsection = (e) =>{
        const { name, value } = e.target;
        setHomeTop((prev) => ({ ...prev, [name]: value }));
    }

    // const updateTopSection = async (el) => {
    //     const updateData = el 
    //     console.log(el);

    //     const formData = new FormData();
    //     formData.append('button_name', updateData.button_name);
    //     formData.append('button_url', updateData.button_url);
    //     formData.append('text', updateData.text);
    //     formData.append('title', updateData.title); 

    //     console.log(formData);
    //     try {
    //         const response = await fetch(`${API}/content/home?section=homeTop`, {
    //             method: 'PATCH',
    //             body: JSON.stringify(formData),
    //             headers: { 
    //                 Authorization: `Bearer ${sum3AdminToken}`
    //             },
    //         });
    //         const data = await response.json();
    //         if (!response.ok) {
    //             throw new Error(data?.message);
    //         }

    //         Swal.fire({
    //             text: data?.message,
    //             icon: "success"
    //         });
    //         await getDetails()
    //     } catch (error) {
    //         Swal.fire({
    //             text: error,
    //             icon: "error"
    //         });
    //     }
    // }; 



    const updateTopSection = async (product) => {  
        const formData = new FormData();
        formData.append('button_name', product?.button_name);
        formData.append('button_url', product?.button_url);
        formData.append('text', product?.text);
        formData.append('title', product?.title); 
        try {
            const response = await fetch(`${API}/content/home?section=homeTop`, {
                method: 'PATCH',
                body: formData,
                headers: {
                    Authorization: `Bearer ${sum3AdminToken}`, // If needed for the request
                },
            });

            if (response.ok) {
                const data = await response.json();
                Swal.fire({
                    text: data.message,
                    icon: 'success',
                });
                await getDetails(); // Refresh product data after update
            } else {
                const data = await response.json();
                throw new Error(data.message);
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
            });
        }
    };





    return (
        <div>
            <br />
            <div class='col-11 m-auto'>

                {/* top section  */}
                <div class='text-start mt-4'>
                    <p class='fs-2 fw-bold'>First Section</p>
                    <div className="form-floating p-0 mt-2">
                        <textarea
                            className="form-control h-auto" 
                            name='title'
                            value={homeTop?.title}
                            onChange={heandelChangTopsection}  
                        />
                        <label>title</label>
                    </div>
                    <div className="form-floating p-0 mt-3">
                        <textarea
                            className="form-control h-auto" 
                            name='text'
                            value={homeTop?.text}
                            onChange={heandelChangTopsection} 
                        />
                        <label>typed title</label>
                    </div>
                    <button class='btn btn-outline-dark mt-2' onClick={()=>updateTopSection(homeTop)}>Update</button>
                </div>

                <div class='my-4' style={{height:'5px', backgroundColor:'black'}} />

                {/* secont section  */}

                <div class='text-start'>
                    <p class='fs-2 fw-bold'>First Section</p>
                    <div className="form-floating p-0 mt-2">
                        <textarea
                            className="form-control h-auto" 
                            name='title'
                            value={homeTop?.title}
                            onChange={heandelChangTopsection}

                        />
                        <label>title</label>
                    </div>
                    <div className="form-floating p-0 mt-3">
                        <textarea
                            className="form-control h-auto"
                            placeholder="Subtitle"
                            name='description'
                            value={homeTop?.text}
                            onChange={heandelChangTopsection}
                        />
                        <label>typed title</label>
                    </div>
                    <button class='btn btn-outline-dark mt-2'>Update</button>
                </div>
            </div>
        </div>
    )
}

export default Home