import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { API } from './api'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const navigate = useNavigate()

  let sum3AdminToken = localStorage.getItem("sum3AdminToken");
  let sum3AdminRole = localStorage.getItem("sum3AdminRole");

  useEffect(()=>{
    if(sum3AdminToken && sum3AdminRole){
      navigate('/dashboard')
    } 
  },[sum3AdminToken,sum3AdminRole])

  const handelCallLogin = async () => {
    if (email != "" && email != undefined && password != "" && password != undefined) {
      try {
        const response = await fetch(`${API}/employees/login`, {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: email, password: password }) // Fixed the 'body' casing and converted email to JSON format
        });

        if (!response.ok) {
          throw new Error('Failed to Login');
        }
        const data = await response.json();
        if (data?.isSuccess) {
          localStorage.setItem("sum3AdminToken", data?.payload?.token);
          localStorage.setItem("sum3AdminRole", data?.payload?.role);

          Swal.fire({
            text: data?.message,
            icon: "success"
          });
          navigate('/dashboard')
        } else {
          Swal.fire({
            text: data?.message,
            icon: "error"
          });
        }
      } catch (error) { 
        Swal.fire({
          text: error,
          icon: "error"
        });
      }
    } else {
      Swal.fire({
        text: 'All Filds is required ',
        icon: "error"
      });
    }
  }
  return (
    <div class='d-flex align-items-center justify-content-center' style={{ height: '100vh', width: '100%', background: 'linear-gradient(#FF823C, #424242)' }}>

      <div class='col-lg-5 col-md-6 col-11' style={{ backdropFilter: 'blur(3px)', backgroundColor: 'rgba(255, 255, 255, 0.3)', borderRadius: ' 32px', boxShadow: ' 30px 20px 60px 0px rgba(145, 192, 255, 0.5), inset -6px -6px 10px 0px rgba(145, 192, 255, 0.6), inset 0px 5px 10px 0px rgb(255, 255, 255,0.5)' }}>
        {/* <img src={require('../image/logo.png')} class='m-2' style={{height:'50px'}} alt="" /> */}
        <br />
        <div class='col-11 m-auto  p-3 '>
          <div class="  form-floating   p-0">
            <input type="email" class="form-control" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <label for="email">Email</label>
          </div>
          <div class="  form-floating mt-4  p-0">
            <input type="text" class="form-control" id="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <label for="password">Password</label>
          </div>

          <button class=' form-control btn btn-outline-light fw-bold p-2 mt-4' onClick={() => handelCallLogin()}>Log-in</button>
        </div>
        <br />
      </div>


    </div>
  )
}

export default Login