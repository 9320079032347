import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import Navbar from './Components/Navbar';
import Admin from './Components/Admin';
import User from './Components/User';
import Employees from './Components/Employees';
import QuestionAnswer from './Components/QuestionAnswer';
import Product from './Components/Product';
import { PrivateRoute } from './Components/PrivateRoute';
import Basic from './Components/Basic';
import Pro from './Components/Pro';
import Intermediate from './Components/Intermediate';
import Service from './Components/Service';
import Home from './Components/Home';

function App() {

  const location = useLocation();
  const isActiveLink = (link) => {
    return link === location.pathname;
  };

  return (
    <div className="App">
      {isActiveLink('/') ? '' :
        <>
          <Navbar />
          <div style={{ height: '70px' }}></div>
        </>
      }
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path='/profile' element={<PrivateRoute><Admin /></PrivateRoute>} />
        <Route path='/user' element={<PrivateRoute><User /></PrivateRoute>} />
        
        <Route path='/employees' element={<PrivateRoute><Employees /></PrivateRoute>} />
        <Route path='/questions-answer' element={<PrivateRoute><QuestionAnswer /></PrivateRoute>} />
        <Route path='/products' element={<PrivateRoute><Product /></PrivateRoute>} />  
        <Route path='/basic' element={<PrivateRoute><Basic /></PrivateRoute>} />  
        <Route path='/pro' element={<PrivateRoute><Pro /></PrivateRoute>} />  
        <Route path='/intermediate' element={<PrivateRoute><Intermediate /></PrivateRoute>} />  
        <Route path='/service' element={<PrivateRoute><Service /></PrivateRoute>} />  
        <Route path='/home' element={<PrivateRoute><Home /></PrivateRoute>} />  





      </Routes>
    </div>
  );
}

export default App;
