import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { API } from './api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
const Product = () => {
    const [products, setProducts] = useState([]);
    const [updatedProducts, setUpdatedProducts] = useState({});
    const [addNewProduct, setAddNewProduct] = useState({
        category: 'Basic'
    })
    const [isAdd, setIsAdd] = useState(false)
    const [search, setSearch] = useState('')
    const [cat, setCat] = useState('')

    const sum3AdminToken = localStorage.getItem('sum3AdminToken');
    const sum3AdminRole = localStorage.getItem('sum3AdminRole')

    const getProductDetails = async () => {
        try {
            const response = await fetch(`${API}/products`, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to get Product Details');
            }
            const data = await response.json();
            setProducts(data?.payload?.products || []);

            // Initialize updatedProducts state
            const initialUpdates = data?.payload?.products.reduce((acc, product) => {
                acc[product.id] = { ...product };
                return acc;
            }, {});
            setUpdatedProducts(initialUpdates);
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
            });
        }
    };

    useEffect(() => {
        getProductDetails();
    }, []);
    // update products
    const handleFieldChange = (productId, field, value) => {
        setUpdatedProducts((prev) => ({
            ...prev,
            [productId]: {
                ...prev[productId],
                [field]: value,
            },
        }));
    };

    const handleImageChange = (event, productId) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                setUpdatedProducts((prev) => ({
                    ...prev,
                    [productId]: {
                        ...prev[productId],
                        image: e.target.result, // Image preview
                        newImageFile: file, // Image file for uploading
                    },
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const updateProduct = async (productId) => {
        const productData = updatedProducts[productId];
        if (!productData) {
            alert('No changes to update');
            return;
        }

        const formData = new FormData();
        formData.append('id', productData.id);
        formData.append('title', productData.title);
        formData.append('subtitle', productData.subtitle);
        formData.append('description', productData.description);
        formData.append('link', productData.link);
        formData.append('buttonText', productData.buttonText);
        formData.append('category', productData.category);


        if (productData.newImageFile) {
            formData.append('file', productData.newImageFile);
        }

        try {
            const response = await fetch(`${API}/products/${productId}`, {
                method: 'PATCH',
                body: formData,
                headers: {
                    Authorization: `Bearer ${sum3AdminToken}`, // If needed for the request
                },
            });

            if (response.ok) {
                const data = await response.json();
                Swal.fire({
                    text: data.message,
                    icon: 'success',
                });
                await getProductDetails(); // Refresh product data after update
            } else {
                const data = await response.json();
                throw new Error(data.message);
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
            });
        }
    };

    // Add product 
    const handleAddProductDetails = (e) => {
        const { name, value } = e.target;
        setAddNewProduct((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddDescription = (value) => {
        setAddNewProduct((prev) => ({ ...prev, description: value }));
    };

    const handleAddNewImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                setAddNewProduct((prev) => ({
                    ...prev, image: e.target.result, // Image preview
                    file: file, // Image file for uploading 
                }));
            };
            reader.readAsDataURL(file);
        }
    };


    const handelAddProduct = async (product) => {
        const productData = product;
        if (!productData) {
            alert('No changes to update');
            return;
        }

        const formData = new FormData();
        formData.append('category', productData.category);
        formData.append('title', productData.title);
        formData.append('subtitle', productData.subtitle);
        formData.append('description', productData.description);
        formData.append('link', productData.link);
        formData.append('buttonText', productData.buttonText);
        formData.append('file', productData.file);

        try {
            const response = await fetch(`${API}/products`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${sum3AdminToken}`, // If needed for the request
                },
            });

            if (response.ok) {
                const data = await response.json();
                Swal.fire({
                    text: data.message,
                    icon: 'success',
                });
                await getProductDetails(); // Refresh product data after update
                setIsAdd(false)
            } else {
                const data = await response.json();
                throw new Error(data.message);
            }
        } catch (error) {
            Swal.fire({
                text: error,
                icon: 'error',
            });
        }
    };

    const handelDeletProduct = async (el) => {
        try {
            const response = await fetch(`${API}/products/${el}`, {
                method: 'DELETE',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }
            Swal.fire({
                text: data?.message,
                icon: "success"
            });
            await getProductDetails()
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        } 
    }   

    return (
        <div>
            {isAdd &&
                <div class='popupStyle'>
                    <div class='col-12 m-auto' style={{ height: '95vh', overflowY: 'auto' }}>
                        <div className="mt-5 border rounded-4 p-3 col-11 m-auto bg-light">
                            <br />
                            <p class='fs-2 fw-bold'>Add New Product</p>

                            <p class="fw-semibold text-start">Category</p>
                            <select class="form-select form-select-lg mt-1" aria-label="Large select example" name="category" value={addNewProduct?.category} onChange={handleAddProductDetails}>
                                <option value="Basic">Basic</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Pro">Pro</option>  
                            </select>

                            <div className="form-floating p-0 mt-2">
                                <textarea
                                    className="form-control h-auto"
                                    placeholder="Title"
                                    name='title'
                                    value={addNewProduct?.title}
                                    onChange={handleAddProductDetails}
                                />
                                <label>Title</label>
                            </div>

                            <div className="form-floating p-0 mt-2">
                                <textarea
                                    className="form-control h-auto"
                                    placeholder="Subtitle"
                                    name='subtitle'
                                    value={addNewProduct?.subtitle}
                                    onChange={handleAddProductDetails}
                                />
                                <label>Subtitle</label>
                            </div>

                            {
                                (addNewProduct?.category == "Basic" || addNewProduct?.category == "Intermediate" || addNewProduct?.category == "Service" || addNewProduct?.category == "Pro") &&
                                <>

                                    <div className="form-floating p-0 mt-2">
                                        <ReactQuill
                                            name='description'
                                            value={addNewProduct?.description}
                                            onChange={(value) => handleAddDescription(value)}
                                            modules={{
                                                toolbar: [
                                                    [{ header: '1' }, { header: '2' }, { font: [] }],
                                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                                    ['bold', 'italic', 'underline'],
                                                    [{ color: [] }, { background: [] }],
                                                    [{ align: [] }],
                                                    ['link', 'image', 'video'],
                                                ],
                                            }}
                                        />
                                    </div>
                                   
                                </>
                            }

                            {
                                (addNewProduct?.category == "Basic" || addNewProduct?.category == "Intermediate" || addNewProduct?.category == "Service") &&
                                <> 
                                    <div class='col-lg-4 col-md-5 col-sm-8 col-11 m-auto'>
                                        <img
                                            src={`${addNewProduct?.image}`}
                                            alt={addNewProduct?.title}
                                            style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
                                        />
                                    </div>

                                    <div class='mt-2'>
                                        <label htmlFor={`addProductNewImg`}>Select Image:</label>
                                        <input
                                            type="file"
                                            id={`addProductNewImg`}
                                            accept="image/*"
                                            onChange={(e) => handleAddNewImage(e)}
                                        />
                                    </div>
                                </>
                            }
                            {
                                (addNewProduct?.category == "Basic" || addNewProduct?.category == "Pro" || addNewProduct?.category == "Service") &&
                                <div className="form-floating p-0 mt-2">
                                    <textarea
                                        className="form-control h-auto"
                                        placeholder="Button Name"
                                        name='buttonText'
                                        value={addNewProduct?.buttonText}
                                        onChange={handleAddProductDetails}
                                    />
                                    <label>Button Name</label>
                                </div>
                            }

                            <div className="form-floating p-0 mt-2">
                                <textarea
                                    className="form-control h-auto"
                                    placeholder="Button URL"
                                    name='link'
                                    value={addNewProduct?.link}
                                    onChange={handleAddProductDetails}
                                />
                                <label>Button URL</label>
                            </div>

                            <div class='d-flex gap-3 my-3'>


                                <button
                                    className="btn btn-success "
                                    onClick={() => handelAddProduct(addNewProduct)}
                                >
                                    Add Product
                                </button>
                                <button class='btn btn-outline-danger' onClick={() => setIsAdd(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            }
            <div className="col-11 m-auto text-start">
                <p className="fs-1 fw-bold my-3 text-center">Products</p>
                <div class='d-flex gap-3 flex-wrap'>

                    {
                        sum3AdminRole == "Admin" &&
                        <button class=' btn btn-dark fw-semibold' onClick={() => setIsAdd(true)}>+ Add Product</button>
                    }
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('')}> All</button>

                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('Basic')}> Basic</button>
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('Intermediate')}> Intermediate</button>
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('Pro')}>  Pro</button>
             

                </div>
                <input type="text" placeholder='Search Products' class='form-control mt-2' value={search} onChange={(e) => setSearch(e?.target?.value)} />

                {products?.filter((el) => { 
                    if (cat === "") {
                        return el.category.toLowerCase() !== "service";
                    }
                    // Filter products that match the selected category (case-insensitive) and are not "Service"
                    return el.category.toLowerCase() !== "service" && el.category.toLowerCase().includes(cat.toLowerCase());
                })?.filter((el) => {
                    if (search == "") {
                        return el;
                    } else if (el.category.toLowerCase().includes(search.toLowerCase())) {
                        return el;
                    } else if (el.title.toLowerCase().includes(search.toLowerCase())) {
                        return el;
                    }
                })?.map((el, i) => (
                    <div key={el.id} className="mt-3 border border-black border-2 rounded-4 p-3">
                        <p class='fw-bold fs-5'>{el?.category}</p>
                        <select class="form-select form-select-lg mt-1" aria-label="Large select example" value={updatedProducts[el.id]?.category || ''} onChange={(e) => handleFieldChange(el.id, 'category', e.target.value)}>
                            <option value="Basic">Basic</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Pro">Pro</option>
                          

                        </select>
                        <div className="form-floating p-0 mt-2">
                            <textarea
                                className="form-control h-auto"
                                placeholder="Title"
                                value={updatedProducts[el.id]?.title || ''}
                                onChange={(e) => handleFieldChange(el.id, 'title', e.target.value)}
                            />
                            <label>Title</label>
                        </div>

                        <div className="form-floating p-0 mt-2">
                            <textarea
                                className="form-control h-auto"
                                placeholder="Subtitle"
                                value={updatedProducts[el.id]?.subtitle || ''}
                                onChange={(e) => handleFieldChange(el.id, 'subtitle', e.target.value)}
                            />
                            <label>Subtitle</label>
                        </div>
                        {
                            (updatedProducts[el.id]?.category == "Basic" || updatedProducts[el.id]?.category == "Intermediate"  || updatedProducts[el.id]?.category == "Service") &&
                            <>

                                <div className="form-floating p-0 mt-2">
                                    <ReactQuill
                                        value={updatedProducts[el.id]?.description || ''}
                                        onChange={(value) => handleFieldChange(el.id, 'description', value)}
                                        modules={{
                                            toolbar: [
                                                [{ header: '1' }, { header: '2' }, { font: [] }],
                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                                ['bold', 'italic', 'underline'],
                                                [{ color: [] }, { background: [] }],
                                                [{ align: [] }],
                                                ['link', 'image', 'video'],
                                            ],
                                        }}
                                    />
                                </div>

                                <div class='col-lg-4 col-md-5 col-sm-8 col-11 m-auto'>
                                    <img
                                        src={`${API}${el.image}`}
                                        alt={el.title}
                                        style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
                                    />

                                    {/* <img
src={`${API}${updatedProducts[el.id]?.image }`}
alt={el.title}
style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
/> */}
                                </div>

                                <div class='mt-2'>
                                    <label htmlFor={`imageUpload-${el.id}`}>Change Image:</label>
                                    <input
                                        type="file"
                                        id={`imageUpload-${el.id}`}
                                        accept="image/*"
                                        onChange={(e) => handleImageChange(e, el.id)}
                                    />
                                </div>
                            </>
                        }




                        {
                            (updatedProducts[el.id]?.category == "Basic" || updatedProducts[el.id]?.category == "Pro" || updatedProducts[el.id]?.category == "Service") &&
                            <div className="form-floating p-0 mt-2">
                                <textarea
                                    className="form-control h-auto"
                                    placeholder="Button Name"
                                    value={updatedProducts[el.id]?.buttonText || ''}
                                    onChange={(e) => handleFieldChange(el.id, 'buttonText', e.target.value)}
                                />
                                <label>Button Name</label>
                            </div>
                        }

                        <div className="form-floating p-0 mt-2">
                            <textarea
                                className="form-control h-auto"
                                placeholder="Button URL"
                                value={updatedProducts[el.id]?.link || ''}
                                onChange={(e) => handleFieldChange(el.id, 'link', e.target.value)}
                            />
                            <label>Button URL</label>
                        </div>
                        {
                            sum3AdminRole == "Admin" &&
                            <div class='d-flex gap-3 mt-3 align-items-center'>

                                <button
                                    className="btn btn-dark"
                                    onClick={() => updateProduct(el.id)}
                                >
                                    Update Product
                                </button>
                                <button class='btn btn-outline-danger' onClick={() => handelDeletProduct(el?.id)}><i class="bi bi-trash-fill"></i> Delete</button>
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div >
    );
};

export default Product;
