import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { API } from './api';
import { RiDeleteBin6Fill } from 'react-icons/ri';

const User = () => {
    const [user, setUser] = useState()
    const [search, setSearch] = useState("")
    const [cat, setCat] = useState('')



    const sum3AdminToken = localStorage.getItem('sum3AdminToken')

    const getUserDetails = async () => {
        try {
            const response = await fetch(`${API}/admin/users`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
            });
            if (!response.ok) {
                throw new Error('Failed to User data');
            }
            const data = await response.json();
            setUser(data?.payload?.users)
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    useEffect(() => {
        getUserDetails()
    }, [])


    const getUserDetailsXl = async () => {
        try {
            const response = await fetch(`${API}/users/execel`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3AdminToken}`
                },
            });
            if (!response.ok) {
                throw new Error('Failed to Download File');
            }
            const data = await response.json();
            const fileURL = `${API}${data?.payload?.fileURL}`
            if (fileURL) {
                window.location.href = fileURL;
            } else {
                throw new Error('File URL is missing in the response');
            }
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    const downloadXl = () =>{ 
        getUserDetailsXl()
    }

    console.log(user);
    return (
        <div>
            <button class='btn btn-outline-info ' onClick={downloadXl} style={{position:'fixed', bottom:'10px', right:'10px'}}> <i class="bi bi-download"></i> Download  File</button>
            <p class='fs-1 fw-bold my-2'>Users</p>
            <div class='col-11 m-auto'>
                <div class='d-flex gap-3'>
                <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('')}> All</button> 
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('Basic')}> Basic</button>
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('Intermediate')}> Intermediate</button>
                    <button class='btn btn-outline-dark fw-bold' onClick={() => setCat('Pro')}>  Pro</button>
                </div>
                <input type="text" placeholder='Search Products' class='form-control mt-2' value={search} onChange={(e) => setSearch(e?.target?.value)} />
            </div>


            <div class='col-11 m-auto mt-2' style={{ overflowX: 'auto' }}>
                <table class="table table-bordered p-0    ">
                    <thead>
                        <tr >
                            <th scope="col">No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th scope="col">Country</th>
                            <th scope="col">City</th>
                            <th scope="col">Mobile</th>
                            {/* <th scope="col">Changes</th> */}
                        </tr>
                    </thead>
                    <tbody class='p-0'>
                        {user?.filter((el) => {
                            if (cat === "") {
                                return el;
                            } else if (el.role && el.role.toLowerCase().includes(cat.toLowerCase())) {
                                return el;
                            } else {
                                return false; // Ensures that elements with null or undefined categories are excluded
                            }
                        })?.filter((el) => {
                            if (search == "") {
                                return el;
                            } else if (el.fullname.toLowerCase().includes(search.toLowerCase())) {
                                return el;
                            } else if (el.email.toLowerCase().includes(search.toLowerCase())) {
                                return el;
                            } else if (el.country.toLowerCase().includes(search.toLowerCase())) {
                                return el;
                            } else if (el.city.toLowerCase().includes(search.toLowerCase())) {
                                return el;
                            }
                            else if (el.mobile.includes(search.toLowerCase())) {
                                return el;
                            }
                        })?.map((el, i) => {
                            return (
                                <tr class='p-0 m-0' >
                                    <th class='p-0' scope="row"><input type="text" class='form-control border-0 p-1 px-1' readOnly style={{ maxWidth: '30px' }} value={i + 1} /></th>
                                    <td class='p-0'><input type="text" class='form-control border-0 p-1' readOnly style={{ minWidth: '150px' }} value={el?.fullname} /></td>
                                    <td class='p-0'><input type="text" class='form-control border-0 p-1' readOnly style={{ minWidth: '250px' }} value={el?.email} /></td>
                                    <td class='p-0'><input type="text" class='form-control border-0 p-1' readOnly style={{ minWidth: '100px' }} value={el?.role} /></td>

                                    <td class='p-0'><input type="text" class='form-control border-0 p-1' readOnly style={{ minWidth: '100px' }} value={el?.country} /></td>
                                    <td class='p-0'><input type="text" class='form-control border-0 p-1' readOnly style={{ minWidth: '100px' }} value={el?.city} /></td>
                                    <td class='p-0'><input type="text" class='form-control border-0 p-1' readOnly style={{ minWidth: '100px' }} value={el?.mobile} /></td>
                                    {/* <td class='p-0'>
                                        <div class='d-flex gap-2'>
                                            <button class='btn  btn-outline-success p-0 px-2 m-1 '>Save</button> <button class='btn m-1  btn-outline-danger p-0 px-2'><RiDeleteBin6Fill /></button>
                                        </div>
                                    </td> */} 
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default User