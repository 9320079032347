import React from 'react'
import { ImSwitch } from 'react-icons/im'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {

  const navigate = useNavigate()
  const logOut = () => {
    localStorage.clear()
    navigate('/')
  }
  return (
    <div class='d-flex align-items-center justify-content-center bg-dark' style={{ height: '70px', position: 'fixed', top: '0', left: '0', width: '100%', zIndex: '5' }}>
      <div class='col-11 m-auto d-flex align-items-center justify-content-between'>

        <img src={require('../image/logo.png')} style={{ height: '50px' }} alt="" />
        <div class='d-flex gap-4 align-items-center'>
          <p class='org fs-4 fw-bold' style={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard')}>Dashboard</p>
          <p class=' fs-4 fw-bold ' style={{ cursor: 'pointer' }} onClick={() => logOut()}><ImSwitch class='logOut' /></p>
        </div>

      </div>

    </div>
  )
}

export default Navbar